module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Forensic Strategy","short_name":"sfs","start_url":"/","background_color":"#860A1C","theme_color":"#860A1C","display":"minimal-ui","icon":"src/images/favicon.ico"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-160692473-1"},
    }]
